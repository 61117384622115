import { useState } from "react";
import styles from "./primarySwitch.module.scss";

const PrimarySwitch = ({ state, onClick }) => {
  const [isOn, setIsOn] = useState(false || state);

  const toggleSwitch = () => {
    setIsOn(!isOn);
    onClick();
  };

  return (
    <div className={styles.container} onClick={toggleSwitch}>
      <div className={`${styles.switch} ${isOn ? styles.on : styles.off}`}>
        <div className={styles.toggle}></div>
      </div>
    </div>
  );
};

export default PrimarySwitch;
