import CustomerPhoto from "../customerPhoto/CustomerPhoto";
import styles from "./customerPhotos.module.scss";

const CustomerPhotos = ({
  photos,
  openGalleryModal,
  markedShotsQt,
  setMarkedShotsQt,
  // selectPhoto,
  getGallery,
  unselectPhoto,
  openPrintingsChart,
  closePrintingsChart,
  updatePrintings,
  ifPrintings,
  downloadSinglePhoto,
}) => {
  return (
    <div className={styles.container}>
      {photos.map((photo, index) => (
        <CustomerPhoto
          key={index}
          photo={photo}
          photos={photos}
          index={index}
          getGallery={getGallery}
          openGalleryModal={openGalleryModal}
          markedShotsQt={markedShotsQt}
          setMarkedShotsQt={setMarkedShotsQt}
          unselectPhoto={unselectPhoto}
          openPrintingsChart={openPrintingsChart}
          closePrintingsChart={closePrintingsChart}
          updatePrintings={updatePrintings}
          ifPrintings={ifPrintings}
          downloadSinglePhoto={downloadSinglePhoto}
        />
      ))}
    </div>
  );
};

export default CustomerPhotos;
