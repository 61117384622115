import { useState } from "react";
import styles from "./customer-gallery-carousel.module.css";
import { useSwipeable } from "react-swipeable";
import CloseButton from "../../../static/svg/CloseButton";
import ArrowRight from "../../../static/svg/ArrowRight";
import ArrowLeft from "../../../static/svg/ArowLeft";
import CarouselPhoto from "./carouselPhoto/CarouselPhoto";

const CustomerGalleryCarousel = (props) => {
  const [activeIndex, setActiveIndex] = useState(props.index);

  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (activeIndex === props.photos.length - 1) {
        return;
      } else {
        setActiveIndex(activeIndex + 1);
      }
    },
    onSwipedRight: () => {
      if (activeIndex === 0) {
        return;
      } else {
        setActiveIndex(activeIndex - 1);
      }
    },
  });

  const clickLeft = () => {
    if (activeIndex === 0) {
      return;
    } else {
      setActiveIndex(activeIndex - 1);
    }
  };

  const clickRight = () => {
    const photosLength = props.isEdited ? props.editedPhotos.length : props.photos.length;

    if (activeIndex === photosLength - 1) {
      return;
    } else {
      setActiveIndex(activeIndex + 1);
    }
  };

  const filteredPhotos = () => {
    return (
      <>
        <CarouselPhoto
          photos={props.isEdited ? props.editedPhotos : props.photos}
          activeIndex={activeIndex}
          selectPhoto={props.selectPhoto}
          unselectPhoto={props.unselectPhoto}
          totalPrice={props.totalPrice}
          selectedShotsQt={props.selectedShotsQt}
          markedShotsQt={props.markedShotsQt}
          setMarkedShotsQt={props.setMarkedShotsQt}
          getGallery={props.getGallery}
          openPrintingsChart={props.openPrintingsChart}
          ifPrintings={props.ifPrintings}
          updatePrintings={props.updatePrintings}
          downloadSinglePhoto={props.downloadSinglePhoto}
        />
      </>
    );
  };

  return (
    <div {...handlers} className={styles.customerGalleryCarousel}>
      {/* -- CLOSE BUTTON -- */}
      <div
        onClick={() => {
          props.closeGalleryModal();
        }}
        className={styles.closeButton}
      >
        <CloseButton color="#FFF" width="25" height="25" />
      </div>
      {/* FILTERED PHOTOS */}
      {filteredPhotos()}

      {/* -- NAVIGATION ARROWS -- */}
      <div className={styles.arrows}>
        <div onClick={clickLeft} className={styles.arrowLeft}>
          <ArrowLeft color={"white"} width="48" height="72" />
        </div>
        <div onClick={clickRight} className={styles.arrowRight}>
          <ArrowRight color={"white"} width="48" height="72" />
        </div>
      </div>
    </div>
  );
};

export default CustomerGalleryCarousel;
