import React, { useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import styles from "./appearanceSettings.module.scss";
import axios from "axios";
import PrimaryButton from "../../../../components/layout/primaryButton/PrimaryButton";

const AppearanceSettings = ({ user, setAlert, getUser }) => {
  const [preview, setPreview] = useState();
  const [file, setFile] = useState();
  const fileInputRef = useRef(null);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1200,
        1200,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleSelectFile = async (e) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const resizedFile = await resizeFile(file);
    setFile(file);
    setPreview(URL.createObjectURL(file));
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const setAvatar = async () => {
    const path = `${user.organization_name}`;
    const avatarID = user.avatar && user.avatar.id ? `${user.avatar.id}` : "";

    let formData = new FormData();
    formData.append("path", path);
    formData.append("photo", file);
    formData.append("avatarID", avatarID);

    await axios
      .post(`upload/avatar`, formData)
      .then((res) => {
        const data = {
          avatarID: res.data.id,
          avatarURL: res.data.url,
        };

        axios
          .put("/users", data)
          .then((res) => {
            setAlert({
              message: "Poprawnie zapisano avatar.",
              type: "success",
            });
            getUser();
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className={styles.container}>
      <div className={styles.avatarSettings}>
        <div className={styles.filePicker}>
          <p>Wybierz swój avatar. Będzie widoczny dla Twoich klientów.</p>
          <div className={styles.avatarSelector}>
            <PrimaryButton
              title="Wybierz plik"
              backgroundColor="#0099F0"
              borderColor="#0099F0"
              color="#FFF"
              onClick={handleButtonClick}
            />

            <input ref={fileInputRef} type="file" accept="image/*" onChange={handleSelectFile} />
          </div>
        </div>
        <div className={styles.avatarPreview}>
          {file ? (
            <>
              <img src={preview} alt="" />
              <button onClick={setAvatar} className={styles.button}>
                Prześlij
              </button>
            </>
          ) : (
            <img src={user.avatar?.url} alt="" />
          )}
        </div>
      </div>
    </div>
  );
};

export default AppearanceSettings;
