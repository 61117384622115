import React from "react";
import { createRoot } from "react-dom/client"; // Nowy import dla React 18
import App from "./App";
import AppContextProvider from "./store/AppContextProvider";

// GLOBAL STYLES
import "bootstrap/dist/css/bootstrap.min.css";
import "./style/global.scss";
import "react-image-crop/dist/ReactCrop.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement); // Tworzenie "root" dla React 18

root.render(
  <AppContextProvider>
    <App />
  </AppContextProvider>
);
