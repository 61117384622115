import React from "react";
import styles from "./modalPreview.module.scss";

import { IoCloseOutline } from "react-icons/io5";

const ModalPreview = ({ preview, setShowPreview }) => {
  return (
    <div className={styles.previewContainer}>
      <div className={styles.preview}>
        <div className={styles.close} onClick={() => setShowPreview(false)}>
          <IoCloseOutline size="24" />
        </div>
        <div dangerouslySetInnerHTML={{ __html: preview }} />
      </div>
    </div>
  );
};

export default ModalPreview;
