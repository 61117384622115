import { useState } from "react";
import { Link } from "react-router-dom";
import styles from "./registration.module.scss";
import axios from "axios";

import Navbar from "../../components/layout/Navbar";
import FormInput from "../../components/layout/Form/form-input/FormInput";

const RegistrationPage = (props) => {
  const [confirmRegister, setConfirmRegister] = useState(false);
  const [errors, setErrors] = useState("");
  const [passwordShow, setPasswordShow] = useState(false);
  const [passwordConfirmShow, setPasswordConfirmShow] = useState(false);

  const [values, setValues] = useState({
    organization: "",
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const passwordType = () => {
    if (passwordShow) {
      return "text";
    } else {
      return "password";
    }
  };

  const passwordConfirmType = () => {
    if (passwordConfirmShow) {
      return "text";
    } else {
      return "password";
    }
  };

  const inputs = [
    {
      id: 1,
      name: "organization",
      type: "text",
      label: "Nazwa Twojej firmy (widoczna dla klientów)",
      errorMessage: "Nazwa firmy musi zawierać od 3 do 42 znaków.",
      pattern: `^[a-zA-Z0-9AaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż !@#$%&*]{3,42}$`,
      required: true,
    },
    {
      id: 2,
      name: "name",
      type: "text",
      label: "Twoje imię",
      errorMessage: "Imię musi zawierać co najmniej 3 litery oraz nie może zawierać znaków specjalnych.",
      pattern: "^[A-Za-zAaĄąBbCcĆćDdEeĘęFfGgHhIiJjKkLlŁłMmNnŃńOoÓóPpRrSsŚśTtUuWwYyZzŹźŻż ]{3,50}$",
      required: true,
    },
    {
      id: 3,
      name: "email",
      type: "email",
      label: "Adres email",
      errorMessage: "Podaj poprawny adres email",
      required: true,
    },
    {
      id: 4,
      name: "password",
      type: passwordType(),
      label: "Hasło",
      errorMessage:
        "Hasło musi składać się z 8 - 20 znaków (bez polskich liter) oraz zawierać przynajmniej jedną cyfrę, jedną wielką literę i jeden znak specjalny.",
      pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
      required: true,
      passwordEye: true,
      showPassword: () => {
        setPasswordShow(!passwordShow);
      },
    },
    {
      id: 5,
      name: "confirmPassword",
      type: passwordConfirmType(),
      label: "Potwierdź hasło",
      errorMessage: "Hasła muszą być takie same.",
      pattern: values.password,
      required: true,
      passwordEye: true,
      showPassword: () => {
        setPasswordConfirmShow(!passwordConfirmShow);
      },
    },
  ];

  const registerUser = async (e) => {
    e.preventDefault();

    const user = {
      ...values,
    };

    await axios
      .post("/users/signup", user)
      .then((response) => {
        console.log(response);
        setConfirmRegister(true);
      })
      .catch((error) => {
        setErrors(error.response.data.message);
      });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Navbar />
      <div className={styles.container}>
        {confirmRegister ? (
          <p className={styles.confirm}>
            Konto zostało poprawnie utworzone.<br></br> Link aktywacyjny został wysłany na podany adres email -{" "}
            <b>{values.email}</b>
            <br></br> Postępuj zgodnie z instrukcją aby aktywować swoje konto.
          </p>
        ) : (
          // <form className={styles.form} onSubmit={registerUser}>
          //   <h2>Maslado znajduje się w fazie testowej.</h2>

          //   <p>
          //     Chcesz dołączyć do grona pierwszych użytkowników testowych, pomóc nam współtworzyć aplikację oraz zdobyć
          //     darmowe pakiety sesji?
          //   </p>

          //   <p className={styles.terms}>Skontaktuj się z nami poprzez email kontakt@maslado.com</p>
          // </form>

          <form className={styles.form} onSubmit={registerUser}>
            <h2>Wypełnij formularz i dołącz do nas!</h2>
            <div className={styles.inputs}>
              {inputs.map((input) => (
                <FormInput
                  key={input.id}
                  {...input}
                  value={values[input.name]}
                  onChange={onChange}
                  background={"#e9e9e9"}
                />
              ))}
            </div>
            <span className="errors">{errors}</span>
            <p>
              Posiadasz już konto? <Link to="/login">Zaloguj się</Link>
            </p>
            <p className={styles.terms}>
              Zakładając konto, wyrażasz zgodę na{" "}
              <Link to="/privacy-policy" target="_blank">
                warunki korzystania
              </Link>{" "}
              z usługi serwisu Maslado
            </p>
            <button>Zarejestruj się</button>
          </form>
        )}
      </div>
    </>
  );
};

export default RegistrationPage;
