import React, { useState } from "react";
import styles from "./carouselPhoto.module.scss";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import PrintingsChartCarousel from "../PrintingsChartCarousel";
import PrimaryButton from "../../../layout/primaryButton/PrimaryButton";
import axios from "axios";
import { useParams } from "react-router-dom";

const CarouselPhoto = ({
  photos,
  getGallery,
  markedShotsQt,
  setMarkedShotsQt,
  activeIndex,
  ifPrintings,
  openPrintingsChart,
  downloadSinglePhoto,
  totalPrice,
  additionalPrintings,
  updatePrintings,
}) => {
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  let params = useParams();

  const selectPhoto = async (index) => {
    setIsButtonLoading(true);

    setTimeout(async () => {
      try {
        const updatedPhotos = photos.map((photo) => {
          if (photos.indexOf(photo) === index) {
            return { ...photo, marked: true };
          }
          return photo;
        });

        const data = {
          markedShotsQt: markedShotsQt + 1,
          photos: updatedPhotos,
        };

        await axios
          .put(`/galleries/${params.id}`, data)
          .then((res) => {
            setMarkedShotsQt(markedShotsQt + 1);
            getGallery();
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
      setIsButtonLoading(false);
    }, 300);
  };

  const unselectPhoto = async (index) => {
    setIsButtonLoading(true);

    setTimeout(async () => {
      try {
        const updatedPhotos = photos.map((photo) => {
          if (photos.indexOf(photo) === index) {
            const resetPrintings = photo.printings.map((printing) => {
              return printing.quantity > 0 ? { ...printing, quantity: 0 } : printing;
            });

            return {
              ...photo,
              marked: false,
              showPrintings: false,
              printings: resetPrintings,
              printingsPrice: 0,
            };
          }
          return photo;
        });

        const data = {
          markedShotsQt: markedShotsQt - 1,
          photos: updatedPhotos,
        };

        await axios
          .put(`/galleries/${params.id}`, data)
          .then((res) => {
            setMarkedShotsQt(markedShotsQt - 1);
            getGallery();
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
      setIsButtonLoading(false);
    }, 300);
  };

  const photoButtons = () => {
    if (photos[activeIndex].marked) {
      return (
        <PrimaryButton
          title={isButtonLoading ? <AiOutlineLoading3Quarters className={styles.spin} /> : "Wybrane"}
          backgroundColor="#0096ea"
          borderColor="#0096ea"
          color="#FFF"
          onClick={() => {
            unselectPhoto(activeIndex);
          }}
        ></PrimaryButton>
      );
    } else if (photos[activeIndex].selected) {
      return (
        <PrimaryButton title="Wysłane" backgroundColor="#FFF" borderColor="#191919" color="#191919"></PrimaryButton>
      );
    } else if (!photos[activeIndex].edited) {
      return (
        <PrimaryButton
          onClick={() => {
            selectPhoto(activeIndex);
          }}
          title={isButtonLoading ? <AiOutlineLoading3Quarters className={styles.spin} /> : "Wybierz"}
          backgroundColor="#FFF"
          borderColor="#0096ea"
          color="#0096ea"
        ></PrimaryButton>
      );
    }
  };

  return (
    <div className={styles.photo}>
      <img src={photos[activeIndex].url} alt="" />
      <div className={styles.controls}>
        <div className="d-flex">
          {photoButtons()}
          {(photos[activeIndex].marked || photos[activeIndex].selected) && ifPrintings ? (
            <button
              onClick={() => openPrintingsChart(activeIndex)}
              className={styles.openPrintings}
              style={photos[activeIndex].printingsPrice ? { background: "#83F29C" } : { background: "inherit" }}
            >
              Wydruki
            </button>
          ) : (
            ""
          )}
          {photos[activeIndex].edited ? (
            <PrimaryButton
              onClick={() => {
                downloadSinglePhoto(photos[activeIndex]);
              }}
              title="Pobierz"
              backgroundColor="#FFF"
              borderColor="#0096ea"
              color="#0096ea"
            ></PrimaryButton>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex">
          <div className={styles.chosenShots}>
            <p>
              Ilość wybranych ujęć: <span>{markedShotsQt}</span>
            </p>
          </div>
          <div className={styles.totalPrice}>
            <p>Do zapłaty: {totalPrice()}</p>
          </div>
        </div>
      </div>
      {photos[activeIndex].showPrintings ? (
        <PrintingsChartCarousel
          photo={photos[activeIndex]}
          additionalPrintings={additionalPrintings}
          closeChart={() => openPrintingsChart(activeIndex)}
          func={(updatedPhoto) => updatePrintings(activeIndex, updatedPhoto)}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default CarouselPhoto;
