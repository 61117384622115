import React from "react";
import styles from "./paymentsSettings.module.scss";
import PaymentsIntegration from "./paymentsIntegration/PaymentsIntegration";
import PaymentsMesage from "./paymentsMessage/PaymentsMesage";

const PaymentsSettings = ({ user, getUser, setAlert }) => {
  return (
    <div className={styles.container}>
      <PaymentsIntegration user={user} setAlert={setAlert} getUser={getUser} />
      <PaymentsMesage setAlert={setAlert} user={user} />
    </div>
  );
};

export default PaymentsSettings;
