import React, { useState } from "react";
import styles from "./paymentsIntegration.module.scss";
import FormInput from "../../../../../components/layout/Form/form-input/FormInput";
import PrimaryButton from "../../../../../components/layout/primaryButton/PrimaryButton";
import PrimarySwitch from "../../../../../components/layout/primarySwitch/PrimarySwitch";
import axios from "axios";

const PaymentsIntegration = ({ user, setAlert, getUser }) => {
  const [testAccess, setTestAccess] = useState(false);
  const [userPayments, setUserPayments] = useState({
    p24ID: "" || user.p24ID,
    CRCkey: "" || user.CRCkey,
    APIkey: "" || user.APIkey,
  });

  const handleChangeUserPayments = async (e) => {
    e.preventDefault();

    if (!testAccess) {
      setAlert({
        message: "Aby zapisać zmiany skonfiguruj integrację. Wprowadź poprawne dane i przetestuj połączenie",
        type: "error",
      });
    } else {
      console.log(userPayments);

      await axios
        .put(`/users`, userPayments)
        .then((res) => {
          setAlert({
            message: "Zmiany zostały zapisane.",
            type: "success",
          });
        })
        .catch((error) =>
          setAlert({
            message: error.response.data.message,
            type: "error",
          })
        );
    }
  };

  const handleTestAccess = async () => {
    const data = {
      p24ID: userPayments.p24ID,
      CRCkey: userPayments.CRCkey,
      APIkey: userPayments.APIkey,
    };

    await axios
      .post(`/payments/test-access`, data)
      .then((res) => {
        setAlert({
          message: res.data.message,
          type: "success",
        });

        setTestAccess(true);
      })
      .catch((error) =>
        setAlert({
          message: error.response.data.message,
          type: "error",
        })
      );
  };

  const handleSwitchPayments = async () => {
    const data = {
      payments: !user.payments,
    };

    await axios
      .put(`/users`, data)
      .then(async (res) => {
        await getUser();

        setAlert({
          message: user.payments ? "Wyłączono płatności." : "Włączono płatności.",
          type: "success",
        });
      })

      .catch((error) =>
        setAlert({
          message: error.response.data.message,
          type: "error",
        })
      );
  };

  return (
    <div className={styles.container}>
      <div className={styles.paymentsIntegration}>
        <p>
          W chwili obecnej oferujemy integrację płatności z platformą Przelewy24. Jeśli masz jakieś pytania odnośnie
          procesu konfiguracji i zakładania konta w serwisie Przelewy24 skorzystaj z formularza kontaktowego.
          Przeprowadzimy Cię przez cały proces.
        </p>
        <div className={styles.form}>
          <FormInput
            onChange={(e) =>
              setUserPayments((prevState) => {
                return { ...prevState, p24ID: e.target.value };
              })
            }
            value={userPayments.p24ID}
            type="number"
            label="Identyfikator Przelewy24"
            name="p24ID"
          />
          <FormInput
            onChange={(e) =>
              setUserPayments((prevState) => {
                return { ...prevState, CRCkey: e.target.value };
              })
            }
            value={userPayments.CRCkey}
            type="text"
            label="Klucz do CRC"
            name="CRCkey"
          />
          <FormInput
            onChange={(e) =>
              setUserPayments((prevState) => {
                return { ...prevState, APIkey: e.target.value };
              })
            }
            value={userPayments.APIkey}
            type="text"
            label="Klucz API"
            name="APIkey"
          />
          <div className={styles.buttonsContainer}>
            {" "}
            <PrimarySwitch state={user.payments} onClick={handleSwitchPayments} />
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <PrimaryButton
                title="Test połączenia"
                backgroundColor="#0099F0"
                borderColor="#0099F0"
                color="#FFF"
                onClick={handleTestAccess}
              />
              <PrimaryButton
                title="Zapisz zmiany"
                backgroundColor="#0099F0"
                borderColor="#0099F0"
                color="#FFF"
                onClick={handleChangeUserPayments}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentsIntegration;
