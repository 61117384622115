import { useContext } from "react";
import { UserContext } from "../../../../store/AppContextProvider";
import { useState } from "react";

import ArrowDown from "../../../../static/svg/ArrowDown";
import styles from "./userSettings.module.scss";
import Logout from "../../../../static/svg/Logout";
import { useNavigate } from "react-router-dom";
import AccountIcon from "../../../../static/svg/AccountIcon";

const UserSettings = () => {
  const { user, setUser } = useContext(UserContext);
  const [active, setActive] = useState(false);

  let navigate = useNavigate();

  const logout = () => {
    setUser({});
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("organization");

    navigate(`/login`);
  };

  return (
    <div className={styles.container} style={active ? { backgroundColor: "#ECF0F4" } : { backgroundColor: "#f9fbfc" }}>
      <div onClick={() => setActive(!active)} className={styles.settingsButton}>
        <div className={styles.userAvatar}>
          <img src={user.avatar?.url} alt="" />
        </div>
        <div className={styles.userInfo}>
          <p className={styles.userName}>{user?.name}</p>
          <p className={styles.userOrganization}>{user?.organization_name}</p>
        </div>
        <div className={styles.moreOptionsIcon} style={active ? { transform: "rotate(180deg)" } : {}}>
          <ArrowDown />
        </div>
      </div>
      {active && (
        <div className={styles.userMenu}>
          <div className={styles.separator}></div>
          <ul>
            <li onClick={() => navigate("/account")}>
              <AccountIcon />
              <p>Moje konto</p>
            </li>
            <li onClick={logout}>
              <Logout />
              <p>Wyloguj się</p>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserSettings;
