import React, { useState, useEffect } from "react";
import PrimaryButton from "../../../../../components/layout/primaryButton/PrimaryButton";
import styles from "./customerPhoto.module.scss";

import PrintingsChart from "../PrintingsChart";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import axios from "axios";

import { useParams } from "react-router-dom";

const CustomerPhoto = ({
  photo,
  photos,
  index,
  openGalleryModal,
  getGallery,
  markedShotsQt,
  setMarkedShotsQt,
  openPrintingsChart,
  closePrintingsChart,
  updatePrintings,
  ifPrintings,
  downloadSinglePhoto,
}) => {
  const [currentPhoto, setCurrentPhoto] = useState(null);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  let params = useParams();

  useEffect(() => {
    if (photo) {
      setCurrentPhoto(photo);
    }
  }, [photo]);

  const selectPhoto = (index) => {
    setIsButtonLoading(true);

    setTimeout(async () => {
      try {
        const updatedPhotos = photos.map((photo) => {
          if (photos.indexOf(photo) === index) {
            return { ...photo, marked: true };
          }
          return photo;
        });

        const data = {
          markedShotsQt: markedShotsQt + 1,
          photos: updatedPhotos,
        };

        await axios
          .put(`/galleries/${params.id}`, data)
          .then((res) => {
            setMarkedShotsQt(markedShotsQt + 1);
            getGallery();
            setIsButtonLoading(false);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    }, 300);
  };

  const unselectPhoto = async (index) => {
    setIsButtonLoading(true);

    setTimeout(async () => {
      try {
        const updatedPhotos = photos.map((photo) => {
          if (photos.indexOf(photo) === index) {
            const resetPrintings = photo.printings.map((printing) => {
              return printing.quantity > 0 ? { ...printing, quantity: 0 } : printing;
            });

            return {
              ...photo,
              marked: false,
              showPrintings: false,
              printings: resetPrintings,
              printingsPrice: 0,
            };
          }
          return photo;
        });

        const data = {
          markedShotsQt: markedShotsQt - 1,
          photos: updatedPhotos,
        };

        await axios
          .put(`/galleries/${params.id}`, data)
          .then((res) => {
            setMarkedShotsQt(markedShotsQt - 1);
            getGallery();
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
      setIsButtonLoading(false);
    }, 300);
  };

  return (
    <div className={styles.container}>
      {photo.showPrintings ? (
        <PrintingsChart
          photo={photo}
          closePrintingsChart={closePrintingsChart}
          func={(updatedPhoto) => updatePrintings(index, updatedPhoto)}
          openPrintingsChart={openPrintingsChart}
        />
      ) : (
        ""
      )}
      <div className={styles.thumbnail} onClick={() => openGalleryModal(index)}>
        <img src={photo.url} alt="photo" />
      </div>
      <div className={styles.controls}>
        {photo.marked ? (
          <div className={styles.buttons}>
            <PrimaryButton
              title={isButtonLoading ? <AiOutlineLoading3Quarters className={styles.spin} /> : "Wybrane"}
              backgroundColor="#0096ea"
              borderColor="#0096ea"
              color="#FFF"
              onClick={() => {
                unselectPhoto(index);
              }}
            ></PrimaryButton>
            {ifPrintings ? (
              <PrimaryButton
                title="Wydruki"
                backgroundColor="#FFF"
                borderColor="#0096ea"
                color="#0096ea"
                onClick={() => {
                  openPrintingsChart(index);
                }}
              ></PrimaryButton>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {photo.selected ? (
          <div className={styles.buttons}>
            <PrimaryButton title="Wysłane" backgroundColor="#FFF" borderColor="#191919" color="#191919"></PrimaryButton>
            {ifPrintings ? (
              <PrimaryButton
                title="Wydruki"
                backgroundColor="#FFF"
                borderColor="#0096ea"
                color="#0096ea"
                onClick={() => {
                  openPrintingsChart(index);
                }}
              ></PrimaryButton>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {!photo.marked && !photo.selected && !photo.edited ? (
          <PrimaryButton
            onClick={() => {
              selectPhoto(index);
            }}
            title={isButtonLoading ? <AiOutlineLoading3Quarters className={styles.spin} /> : "Wybierz"}
            backgroundColor="#FFF"
            borderColor="#0096ea"
            color="#0096ea"
          ></PrimaryButton>
        ) : (
          ""
        )}
        {photo.edited ? (
          <PrimaryButton
            onClick={() => {
              downloadSinglePhoto(photo);
            }}
            title="Pobierz"
            backgroundColor="#FFF"
            borderColor="#0096ea"
            color="#0096ea"
          ></PrimaryButton>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default CustomerPhoto;
