import axios from "axios";
import styles from "./customerGalleryPage.module.scss";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../store/AppContextProvider";
import { v4 as uuidv4 } from "uuid";
import { saveAs } from "file-saver";

import CustomerPage from "../../../components/layout/customerPage/CustomerPage";
import PrintingsChart from "./components/PrintingsChart";
import LoadingBar from "../../../components/layout/LoadingBar";
import CustomerGalleryCarousel from "../../../components/customer-panel/customer-gallery-carousel/CustomerGalleryCarousel";
import ConfirmationBox from "../../../components/layout/confirmationBox/ConfirmationBox";
import ArrowDown from "../../../static/svg/ArrowDown";
import Alert from "../../../components/layout/Alert/Alert";
import QuestionMarkIcon from "../../../static/svg/QuestionMarkIcon";
import ModalDownloadPhotos from "./components/modalDownloadPhotos/ModalDownloadPhotos";
import ReturnButton from "../../../components/layout/returnButton/ReturnButton";
import PrimaryButton from "../../../components/layout/primaryButton/PrimaryButton";
import Photos from "../../galleries/gallery-page/components/photos/Photos";
import CustomerPhotos from "./components/customerPhotos/CustomerPhotos";
import ModalPreview from "../../../components/layout/modalPreview/ModalPreview";

const CustomerGalleryPage = (props) => {
  const [gallery, setGallery] = useState({});
  const [customer, setCustomer] = useState({});
  const [customerID, setCustomerID] = useState("");
  const [title, setTitle] = useState("");
  const [shotsQt, setShotsQt] = useState(0);
  const [selectedShotsQt, setSelectedShotsQt] = useState("");
  const [markedShotsQt, setMarkedShotsQt] = useState("");
  const [photos, setPhotos] = useState([]);
  const [markedPhotos, setMarkedPhotos] = useState([]);
  const [selectedPhotos, setSelectedPhotos] = useState([]);
  const [editedPhotos, setEditedPhotos] = useState([]);
  const [selectionPhotos, setSelectionPhotos] = useState([]);
  const [showFilteredPhotos, setShowFilteredPhotos] = useState("all");
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [price, setPrice] = useState(0);
  const [payments, setPayments] = useState(false);
  const [additionalShotPrice, setAdditionalShotPrice] = useState(0);
  const [additionalPrintings, setAdditionalPrintings] = useState([]);
  const [paid, setPaid] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [galleryModal, setGalleryModal] = useState(false);
  const [question, setQuestion] = useState("");
  const [action, setAction] = useState();
  const [needConfirmation, setNeedConfirmation] = useState(false);
  const [index, setIndex] = useState(1);
  const [alert, setAlert] = useState({
    message: "",
    type: "",
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [preview, setPreview] = useState(null);
  const [showPreview, setShowPreview] = useState(null);
  const [downloadURL, setDownloadURL] = useState("");
  const [isEdited, setIsEdited] = useState(false);
  const [isButtonLoading, setIsButtonLoading] = useState(false);

  const { user, setUser } = useContext(UserContext);

  let params = useParams();
  let navigate = useNavigate();

  const calculateRemainingAmount = () => {
    let total = 0;

    if (markedShotsQt <= 0) {
      total = price - paid;
    } else if (markedShotsQt > 0) {
      let printingsPrice = 0;
      photos.forEach((photo) => {
        return (printingsPrice = printingsPrice + photo.printingsPrice);
      });

      if (markedShotsQt - shotsQt <= 0) {
        total = price - paid + printingsPrice;
      } else {
        total = (markedShotsQt - shotsQt) * additionalShotPrice + (price - paid) + printingsPrice;
      }
    }

    setRemainingAmount(total);
  };

  useEffect(() => {
    setLoading(true);
    calculateRemainingAmount();
    getGallery();

    getCustomer();
    updateViewDate();
    totalPrice();
    setLoading(false);
  }, [customerID]);

  const getPhotographer = async (userID) => {
    await axios
      .get(`/users/find-user/${userID}`)
      .then((res) => {
        setPayments(res.data.payments);
      })
      .catch((error) => console.log(error));

    await axios
      .get(`/user-options/${userID}`)
      .then((res) => {
        setPreview(res.data.paymentsMessage);
      })
      .catch((error) => console.log(error));
  };

  const getGallery = async () => {
    await axios.get(`/galleries/${params.id}`).then((res) => {
      getPhotographer(res.data.user);
      setGallery(res.data);
      setTitle(res.data.title);
      if (gallery.status != "edited") {
        let x = res.data.photos.filter((photo) => photo.edited === false);
        setPhotos(x);
      } else {
        setPhotos(res.data.photos);
      }
      // let editedPhotos = photos.filter((photo) => photo.edited === true);
      setEditedPhotos(res.data.editedPhotos);
      setPrice(res.data.price);
      setPaid(res.data.paid);
      setShotsQt(res.data.shotsQt);
      setSelectedShotsQt(res.data.selectedShotsQt);
      setMarkedShotsQt(res.data.markedShotsQt);
      setAdditionalShotPrice(res.data.additionalShotPrice);
      setAdditionalPrintings(res.data.additionalPrintings);
      setCustomerID(res.data.customer);
    });
  };

  const getCustomer = async () => {
    await axios
      .get(`/customers/${customerID}`)
      .then((res) => setCustomer(res.data))
      .catch((error) => console.log(error));
  };

  const updateViewDate = async () => {
    let today = new Date();
    let date = today.getDate() + "-" + parseInt(today.getMonth() + 1) + "-" + today.getFullYear();

    let updatedGallery = {
      viewDate: date,
    };

    await axios
      .put(`/galleries/viewDate/${params.id}`, updatedGallery)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };

  // const selectPhoto = async (index) => {
  //   setIsButtonLoading(true);

  //   try {
  //     const updatedPhotos = photos.map((photo) => {
  //       if (photos.indexOf(photo) === index) {
  //         return { ...photo, marked: true };
  //       }
  //       return photo;
  //     });

  //     const data = {
  //       markedShotsQt: markedShotsQt + 1,
  //       photos: updatedPhotos,
  //     };

  //     await axios
  //       .put(`/galleries/${params.id}`, data)
  //       .then((res) => {
  //         setMarkedShotsQt(markedShotsQt + 1);
  //         getGallery();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   } catch (error) {
  //     console.log(error);
  //   }

  //   setIsButtonLoading(false);
  // };

  // const unselectPhoto = async (index) => {
  //   const updatedPhotos = photos.map((photo) => {
  //     if (photos.indexOf(photo) === index) {
  //       const resetPrintings = photo.printings.map((printing) => {
  //         return printing.quantity > 0 ? { ...printing, quantity: 0 } : printing;
  //       });

  //       return {
  //         ...photo,
  //         marked: false,
  //         showPrintings: false,
  //         printings: resetPrintings,
  //         printingsPrice: 0,
  //       };
  //     }
  //     return photo;
  //   });

  //   const data = {
  //     markedShotsQt: markedShotsQt - 1,
  //     photos: updatedPhotos,
  //   };

  //   await axios
  //     .put(`/galleries/${params.id}`, data)
  //     .then((res) => {
  //       setMarkedShotsQt(markedShotsQt - 1);
  //       getGallery();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const openPrintingsChart = async (index) => {
    setPhotos((prevPhotos) => prevPhotos.map((photo, i) => (i === index ? { ...photo, showPrintings: true } : photo)));
  };

  const closePrintingsChart = async (index) => {
    setPhotos((prevPhotos) => prevPhotos.map((photo, i) => (i === index ? { ...photo, showPrintings: false } : photo)));

    getGallery();
  };

  const updatePrintings = async (index, updatedPhoto) => {
    updatedPhoto = { ...updatedPhoto, showPrintings: false };
    console.log(updatedPhoto);
    let updatedPhotos = [...photos];
    updatedPhotos[index] = updatedPhoto;

    setSelectionPhotos(updatedPhotos);

    const data = {
      photos: updatedPhotos,
    };

    await axios
      .put(`/galleries/${params.id}`, data)
      .then((res) => {
        getGallery();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const sendGallery = async () => {
    setNeedConfirmation(false);
    setLoading(true);

    const selectedPhotos = [];
    photos.map((photo) => {
      if (photo.marked === true) {
        selectedPhotos.push(photo);
      }
    });

    if (selectedPhotos.length <= 0) {
      setLoading(false);
      return setAlert({
        message: "Nie dokonano wyboru.",
        type: "info",
      });
    }

    const newSelection = {
      gallery: gallery._id,
      photos: selectedPhotos,
    };

    await axios
      .post("/selections", newSelection)
      .then((res) => {
        const data = {
          galleryTitle: title,
          customerName: customer.name,
          organizationEmail: customer.organizationEmail,
        };

        const updatedPhotos = photos.map((photo) => {
          if (photo.marked === true) {
            return { ...photo, marked: false, selected: true };
          }
          return photo;
        });

        const newGallery = {
          ...gallery,
          photos: updatedPhotos,
          editedPhotos: editedPhotos,
          selectedShotsQt: selectedShotsQt + selectedPhotos.length,
          status: "selected",
        };

        axios
          .post("/galleries/send-to-photographer", data)
          .then((res) => {
            axios
              .put(`/galleries/${params.id}`, newGallery)
              .then((res) => {
                setAlert({
                  message: "Wysłano wybór do fotografa.",
                  type: "success",
                });
                getGallery();
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((error) => {
            console.log(error);
          });
        // navigate(`/customer-panel`);
      })
      .catch((error) => {
        setAlert({
          message: error.response.data.message,
          type: "error",
        });
      });

    setLoading(false);
  };

  const totalPrice = () => {
    if (markedShotsQt <= 0) {
      return <span>{price - paid} zł</span>;
    } else if (markedShotsQt > 0) {
      let printingsPrice = 0;
      photos.forEach((photo) => {
        return (printingsPrice = printingsPrice + photo.printingsPrice);
      });

      if (markedShotsQt - shotsQt <= 0) {
        return <span>{price - paid + printingsPrice} zł</span>;
      } else {
        return <span>{(markedShotsQt - shotsQt) * additionalShotPrice + (price - paid) + printingsPrice} zł</span>;
      }
    }
  };

  const printingsPrice = () => {
    if (markedShotsQt <= 0) {
      return 0;
    } else if (markedShotsQt > 0) {
      let printingsPrice = 0;
      photos.forEach((photo) => {
        return (printingsPrice = printingsPrice + photo.printingsPrice);
      });
      return printingsPrice;
    }
  };

  const openGalleryModal = (index) => {
    setIndex(index);
    setGalleryModal(true);
  };

  const closeGalleryModal = () => {
    setGalleryModal(false);
  };

  const confirmAction = (question, action) => {
    setQuestion(question);
    setNeedConfirmation(true);
    setAction(() => action);
  };

  const downloadSinglePhoto = async (photo) => {
    saveAs(photo.url, `${photo.title}.jpg`);
  };

  const downloadMultiplePhotos = async () => {
    const data = {
      id: gallery._id,
      photos: editedPhotos,
      userID: gallery.user,
    };

    await axios
      .post(`/upload/download`, data)
      .then((res) => {
        setModalOpen(true);
        setDownloadURL(res.data.url);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const payGallery = async () => {
    const payment = {
      customerEmail: customer.email,
      galleryId: gallery._id,
      userId: gallery.user,
      sessionId: uuidv4(),
      price: remainingAmount,
      amount: remainingAmount * 100,
      description: "Opłata za sesję",
    };

    console.log(payment);

    await axios
      .post(`payments/register-payment`, payment)
      .then((res) => {
        window.open(`https://secure.przelewy24.pl/trnRequest/${res.data.token}`, "_blank");
      })
      .catch((error) => console.log(error.response.data));
  };

  const filteredEditedPhotos = () => {
    return (
      <CustomerPhotos
        photos={editedPhotos}
        openGalleryModal={openGalleryModal}
        openPrintingsChart={openPrintingsChart}
        closePrintingsChart={closePrintingsChart}
        updatePrintings={updatePrintings}
        ifPrintings={gallery.ifPrintings}
        downloadSinglePhoto={downloadSinglePhoto}
      />
    );
  };

  const filteredPhotos = () => {
    return (
      <CustomerPhotos
        photos={photos}
        openGalleryModal={openGalleryModal}
        markedShotsQt={markedShotsQt}
        setMarkedShotsQt={setMarkedShotsQt}
        getGallery={getGallery}
        openPrintingsChart={openPrintingsChart}
        closePrintingsChart={closePrintingsChart}
        updatePrintings={updatePrintings}
        ifPrintings={gallery.ifPrintings}
      />
    );
  };

  return (
    <CustomerPage>
      {galleryModal && (
        <CustomerGalleryCarousel
          closeGalleryModal={closeGalleryModal}
          editedPhotos={editedPhotos}
          photos={photos}
          index={index}
          markedShotsQt={markedShotsQt}
          setMarkedShotsQt={setMarkedShotsQt}
          getGallery={getGallery}
          openPrintingsChart={openPrintingsChart}
          additionalPrintings={additionalPrintings}
          updatePrintings={updatePrintings}
          selectedShotsQt={selectedShotsQt}
          totalPrice={totalPrice}
          ifPrintings={gallery.ifPrintings}
          isEdited={isEdited}
          downloadSinglePhoto={downloadSinglePhoto}
        ></CustomerGalleryCarousel>
      )}
      {modalOpen && <ModalDownloadPhotos downloadURL={downloadURL} closeModal={() => setModalOpen(false)} />}
      {loading ? (
        <LoadingBar />
      ) : (
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.returnButton}>
              <ReturnButton title="Powrót do listy galerii" url="/customer-panel" />

              {remainingAmount > 0 ? (
                payments ? (
                  <PrimaryButton
                    title="Opłać sesję"
                    color="#FFF"
                    backgroundColor="#0FA943"
                    borderColor="#0FA943"
                    onClick={() =>
                      confirmAction(
                        "Czy jesteś pewien, ze chcesz opłacić sesję? W następnym kroku zostaniesz przekierowany do płatności.",
                        payGallery
                      )
                    }
                  />
                ) : (
                  <PrimaryButton
                    title="Opłać sesję"
                    color="#FFF"
                    backgroundColor="#0FA943"
                    borderColor="#0FA943"
                    onClick={() => setShowPreview(true)}
                  />
                )
              ) : (
                <PrimaryButton title="Opłacona" color="#FFF" backgroundColor="#0FA943" borderColor="#0FA943" />
              )}

              {payments ? (
                remainingAmount > 0 ? (
                  <PrimaryButton
                    title="Opłać sesję"
                    color="#FFF"
                    backgroundColor="#0FA943"
                    borderColor="#0FA943"
                    onClick={() =>
                      confirmAction(
                        "Czy jesteś pewien, ze chcesz opłacić sesję? W następnym kroku zostaniesz przekierowany do płatności.",
                        payGallery
                      )
                    }
                  />
                ) : (
                  <PrimaryButton title="Opłacona" color="#FFF" backgroundColor="#0FA943" borderColor="#0FA943" />
                )
              ) : (
                ""
              )}
            </div>
            <div className={styles.title}>
              <h3>{title}</h3>
              <p>Wybierz swoje ujęcia i odeślij wybór do fotografa</p>
            </div>
          </div>
          <div className={styles.controls}>
            <div className={styles.chosenShots}>
              <p>
                Ilość wybranych ujęć: <span>{markedShotsQt}</span>
              </p>
            </div>
            <div className={styles.totalPrice}>
              <p>Do zapłaty: {totalPrice()}</p>
              <div className={styles.icon}>
                <QuestionMarkIcon />
                <div className={styles.detailedPriceInfo}>
                  <p>Podstawowa cena sesji: {price} zł</p>
                  <p>
                    Cena dodatkowych ujęć:{" "}
                    {markedShotsQt - shotsQt <= 0 ? <>0</> : <>{(markedShotsQt - shotsQt) * additionalShotPrice}</>} zł
                  </p>
                  <p>Cena dodatkowych wydruków: {printingsPrice()} zł</p>
                  <p>Zapłacono: {paid} zł</p>
                </div>
              </div>
            </div>
            <PrimaryButton
              title="Wyślij wybór"
              color="#FFF"
              backgroundColor="#0099F0"
              borderColor="#0099F0"
              onClick={() => confirmAction("Czy jesteś pewien, ze chcesz wysłać swój wybór fotografowi?", sendGallery)}
            />
            {editedPhotos && editedPhotos.length > 0 ? (
              <PrimaryButton
                title="Pobierz wszystkie"
                color="#0099F0"
                backgroundColor="#FFF"
                borderColor="#0099F0"
                onClick={downloadMultiplePhotos}
              />
            ) : (
              ""
            )}
          </div>
          <div className={styles.gallery}>
            <div className={styles.tabs}>
              <div
                onClick={() => setIsEdited(false)}
                className={styles.tab}
                style={isEdited ? {} : { fontWeight: "500", borderBottom: "2px solid #0099F0" }}
              >
                Zdjęcia do wyboru
              </div>
              <div
                onClick={() => setIsEdited(true)}
                className={styles.tab}
                style={isEdited ? { fontWeight: "500", borderBottom: "2px solid #0099F0" } : {}}
              >
                Zdjęcia gotowe
              </div>
            </div>
            <div className={styles.photos}>{isEdited ? filteredEditedPhotos() : filteredPhotos()}</div>
          </div>
        </div>
      )}
      {needConfirmation ? (
        <ConfirmationBox
          positiveAction={() => action()}
          negativeAction={() => {
            setNeedConfirmation(false);
          }}
          question={question}
        />
      ) : (
        ""
      )}

      {alert.message ? (
        <Alert
          alert={alert}
          close={() =>
            setAlert({
              message: "",
              type: "",
            })
          }
        />
      ) : (
        ""
      )}

      {showPreview && <ModalPreview preview={preview} setShowPreview={setShowPreview} />}
    </CustomerPage>
  );
};

export default CustomerGalleryPage;
