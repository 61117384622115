import { useState } from "react";
import styles from "./contact.module.scss";
import axios from "axios";
import Alert from "../../components/layout/Alert/Alert";
import Page from "../../components/layout/page/Page";
import FormInput from "../../components/layout/Form/form-input/FormInput";
import FormTextarea from "../../components/layout/Form/FormTextarea";
import PrimaryButton from "../../components/layout/primaryButton/PrimaryButton";

const ContactPage = (props) => {
  const [title, setTitle] = useState("");
  const [question, setQuestion] = useState("");

  const [alert, setAlert] = useState({
    message: "",
    type: "",
  });

  const sendQuestion = async (e) => {
    e.preventDefault();

    const data = {
      title: title,
      question: question,
    };

    await axios
      .post("/contacts", data)
      .then((res) => {
        setTitle(" ");
        setQuestion(" ");
        setAlert({
          message: "Wiadomość została wysłana.",
          type: "success",
        });
      })
      .catch((error) => {
        setAlert({
          message: "Zmiany zostały zapisane.",
          type: "error",
        });
      });
  };

  return (
    <Page>
      <div className={styles.container}>
        <div className={styles.headerTitle}>
          <h3>Formularz kontaktowy</h3>
          <p>
            Jeśli masz jakieś pytanie lub znalazłeś błąd w aplikacji, napisz do nas. Odpowiedź wyślemy na adres email na
            który zarejestrowane jest Twoje konto.
          </p>
        </div>

        <form onSubmit={sendQuestion}>
          <FormInput
            onChange={(e) => setTitle(e.target.value)}
            value={title}
            type="text"
            label="Tytuł wiadomości"
            name="title"
            pattern=".{1,50}"
            errorMessage="Tytuł wiadomości jest wymagany i może zawierać maksymalnie 50 znaków."
            required
          />
          <FormTextarea
            onChange={(event) => setQuestion(event.target.value)}
            label="Miejsce na Twoją wiadomość"
            value={question}
            name="question"
            id="question"
            cols="30"
            rows="10"
            errorMessage="Nie zapomnij wpisać treści wiadomości."
            required
          />
          <div className={styles.actionButtons}>
            <PrimaryButton title="Wyślij" backgroundColor="#0099F0" borderColor="#0099F0" color="#FFF" />
          </div>
        </form>
      </div>
      {alert.message ? (
        <Alert
          alert={alert}
          close={() =>
            setAlert({
              message: "",
              type: "",
            })
          }
        />
      ) : (
        ""
      )}
    </Page>
  );
};

export default ContactPage;
