import React, { useEffect, useState } from "react";
import styles from "./paymentsMessage.module.scss";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "draft-js/dist/Draft.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import PrimaryButton from "../../../../../components/layout/primaryButton/PrimaryButton";
import axios from "axios";
import ModalPreview from "../../../../../components/layout/modalPreview/ModalPreview";
import ModalDownloadPhotos from "../../../../customer-panel/customer-gallery-page/components/modalDownloadPhotos/ModalDownloadPhotos";

const PaymentsMesage = ({ user, setAlert }) => {
  const [loading, setLoading] = useState(false);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [preview, setPreview] = useState(null);
  const [showPreview, setShowPreview] = useState(null);
  const [userOptions, setUserOptions] = useState({});

  useEffect(() => {
    getUserOptions();
  }, []);

  const getUserOptions = async () => {
    setLoading(true);
    await axios
      .get(`/user-options/${user._id}`)
      .then((res) => {
        setUserOptions(res.data);

        const paymentsMessage = res.data.paymentsMessage || "";

        // Konwersja paymentsMessage HTML do draft-js state
        const contentBlock = htmlToDraft(paymentsMessage);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          setEditorState(EditorState.createWithContent(contentState));
        }
      })
      .catch((error) => error);
    setLoading(false);
  };

  const handleEditorChange = (newState) => {
    setEditorState(newState);
  };

  const handleShowPreview = () => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);
    setPreview(htmlContent); // Ustawiamy HTML do podglądu
    setShowPreview(true);
  };

  const handlePaymentsMessage = async () => {
    setLoading(true);

    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const htmlContent = draftToHtml(rawContentState);

    const data = {
      paymentsMessage: htmlContent,
    };

    await axios
      .put("/user-options", data)
      .then((res) => {
        setAlert({
          message: "Poprawnie zapisano zmiany.",
          type: "success",
        });
      })
      .catch((error) => error);
    setLoading(false);
  };

  return (
    <div className={styles.container}>
      <p>
        Jeśli nie posiadasz konta w Przelewy24 możesz wyświetlić swojemu klientowi informacje z dostępnymi metodami
        płatności za pomocą poniższego edytora tekstu. Informacje będą widoczne tylko wtedy, gdy płatności automatyczne
        są wyłączone (przycisk po lewej stronie jest szary).
      </p>
      <div className={styles.editor}>
        <Editor
          editorState={editorState}
          onEditorStateChange={handleEditorChange}
          toolbar={{
            options: ["inline", "blockType", "fontSize", "list", "textAlign", "history"],
          }}
          placeholder="Zacznij pisać..."
        />
      </div>
      <div className={styles.buttonsContainer}>
        <PrimaryButton
          title="Podgląd"
          backgroundColor="#0099F0"
          borderColor="#0099F0"
          color="#FFF"
          onClick={handleShowPreview}
        />
        <PrimaryButton
          title="Zapisz zmiany"
          backgroundColor="#0099F0"
          borderColor="#0099F0"
          color="#FFF"
          onClick={handlePaymentsMessage}
        />
      </div>

      {showPreview && <ModalPreview preview={preview} setShowPreview={setShowPreview} />}
    </div>
  );
};

export default PaymentsMesage;
